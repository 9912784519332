<template>
  <div>
    <v-img :src="cover" height="250" aspect-ratio="16/9">
      <div class="d-flex justify-center align-center backdrop-grad">
        <div class="display-3 white--text">TERM AND CONDITION</div>
      </div>
    </v-img>

    <v-container id="term-and-condition" class="py-6">
      <v-card class="py-6 px-4">
        <div v-html="terms"></div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      terms: "",
      cover: "",
    };
  },
  mounted() {
    this.terms = this.$store.getters.g_profile.term_and_condition;
    setTimeout(() => {
      this.cover = this.$store.getters.g_profile.home_cover;
    }, 200);
  },
};
</script>

<style lang="scss">
#term-and-condition {
  li {
    margin-bottom: 10px;
  }
}
</style>
